<!-- eslint-disable -->
<template>
    <div class="flex items-center justify-center" style="background-image: url(); background-size: cover; height: 100vh">
        <Alert />
        <div class="w-50-l w-90">
            <div class="tc pb4">
                <img src="../../assets/images/simplebks-logo.png" style="width: 180px" alt="simplebks-logo" />
            </div>

            <div class="pa2" style="background-color: white; border-radius: 5px;">
                <div class="tc pt4 pb4">
                    <img
                        :src="require('@/assets/images/credit-card-new-payment-successful-svgrepo-com.svg')"
                        width="100px"
                        alt="payment icon"
                    />
                </div>
                <div class="tc pb3">
                    <div v-if="state.message">
                        <div v-if="state.status" class="alert alert-success lh-copy">{{ state.message  }}</div>
                        <div v-else class="alert alert-danger lh-copy">{{ state.message  }}</div>
                    </div>
                    <div class="lh-copy">
                        This invoice was issued through Simplebks. Accounting Made Easy! 💛 For Small Businesses.
                    </div>
                </div>

                <div id="form" class="tc mt3 mb5">
                    Not heard of Simplebks? <a href="https://simplebks.com" class="b">Get started</a>.
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { reactive, onMounted, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert'

export default {
    name: 'MarkAsPaidNotification',
    components: { Alert },

    setup() {
        const store = useStore()
        const route = useRoute()
        const state = reactive({
            status: false,
            message: ''
        })

        onBeforeMount(async () => {
            const { id } = route.params
            const payload = { id }
            await store.dispatch('Invoice/sendMarkAsPaidNotification', payload).then((resp) => {
                state.status = resp.status
                state.message = resp.message
            })
        })

        onMounted(() => {})

        return { state }
    }
}
</script>

<style scoped></style>
